
/*bg de contenedor de video*/
.bgCountentVideo {
    position: relative;
    padding: 100px 0 600px;
    margin-bottom: 250px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    border-radius: 0 0 50% 50%;
    height: 200px;
  }
  
  .bgCountentVideo .info {
    width: 60%;
    margin: 0 auto 30px;
  }
  
  .bgCountentVideo .info h2 {
    font-size: 40px;
    color: white;
    font-weight: bold;
  }
  
  .bgCountentVideo .countentVideoInfo {
    position: relative;
    text-align: center;
    color: white;
  }
  
  .bgCountentVideo::before {
    border-radius: 0 0 50% 50%;
    background-color: rgba(0, 0, 0, 0.47);
  
  }
  
  .bgCountent::before {
    background-color: rgba(0, 0, 0, 0.6);
  }
  
  .bgFormHome::before {
    background-color: rgba(0, 0, 0, 0.0);
  }
  
  .bgCountentVideo::before {
    content: '';
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }
  
  .videoContent {
    width: 60%;
    height: 460px;
    margin: 0 auto -80px;
    border-radius: 20px;
    position: relative;
  }
  
  @media screen and (max-width:869px) {
    .videoContent {
        width: 50%;
        height: 360px;
        margin: 0 auto 20px;
        top: 150px;
    }
  
  }
  
  @media screen and (max-width:569px) {
    .bgCountentVideo {
        margin-bottom: 200px;
        border-radius: 0 0 15% 15%;
    }
  
    .bgCountentVideo::before {
        border-radius: 0 0 15% 15%;
    }
  
    .bgCountentVideo .info {
        width: 90%;
        margin: 0 auto -30px;
    }
  
    .videoContent {
        width: 90%;
        height: 280px;
        top: 80px;
    }
  }